import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Policy from './style'
export default function UsagePolicySection(){
return(
<Policy>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
        <Policy.Box>
          <Policy.Title as="h3">Usage Policy</Policy.Title>
        </Policy.Box>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col className="col-xxl-8 col-xl-9 col-lg-10">
        <Policy.Content>
          
          <Policy.ContentBox>
            
            <Policy.Text>We-Connect has been dedicated to providing the best services since its establishment. We fully support the idea of fighting spam and any other form of mailing which could potentially hurt someone, and as such we strive for excellence in all aspects related thereto.</Policy.Text>
            <Policy.Text>The email standards we have set out in this policy will help to ensure that your emails are always of the highest quality. It is important for us to maintain the highest standards of email sending. We do this by ensuring that all emails are scanned and checked before they're sent, in order to make sure your message is coherent with this Usage Policy. Please familiarize yourself with this policy before you start sending messages via our Service.</Policy.Text>
            <Policy.Text>Below you can find core statistics the We-Connect team uses when reviewing email accounts and sending practices. Please familiarize yourself with this chart and work to stay below these acceptable thresholds. Accounts with statistics above these numbers may be subjected to account suspension and, in some cases, or in case of our Terms of Service violation with full account termination. Prior warning of such actions will be given in all cases unless the risk is deemed to be critical to the security of We-Connect’s infrastructure.</Policy.Text>
          </Policy.ContentBox>
          <Policy.ContentBox>
            <Policy.TitleSmall as="h3">1. SENDING QUALITY METRICS</Policy.TitleSmall>
            <Policy.SubTitle as="h5">a) Bounce rate</Policy.SubTitle>
            <Policy.Text>We assess that a bounce rate that is acceptable when it comes to deliverability, as well as your positive sender score, is oriented below 15%. Please, make sure your bounce rate does not exceed 15% at any time.</Policy.Text>
            <Policy.SubTitle as="h5">b) Spam complaints</Policy.SubTitle>
            <Policy.Text>Receiving a spam complaint is always an indication of sending issues and we assess that the rate of spam complaints shall be as low as possible but unconditionally below 1%. If this metric exceeds 1%, we retain the option to suspend the account immediately.</Policy.Text>
            <Policy.Text>If you may have a low-quality list with outdated or incorrect emails that hurt your domain reputation and negatively affect future campaigns.</Policy.Text>
            <Policy.SubTitle as="h5">c) Opt-outs</Policy.SubTitle>
            <Policy.Text>We assess that the opt-out (unsubscribe) level calculated as the ratio of the number of opt-outs to the number of initial messages sent shall be below 10%. Please, make sure you keep your opt-out rate under 10%.</Policy.Text>
            <Policy.SubTitle as="h5">d) Invalid rates</Policy.SubTitle>
            <Policy.Text>We assess that the ratio of the sum of the number of prospects marked invalid in all campaigns of a given company to the sum of prospects in all campaigns of a given company shall be below 20%.</Policy.Text>
            <Policy.SubTitle as="h5">e) Open rate</Policy.SubTitle>
            <Policy.Text>Email open rate is the percentage of subscribers who open a specific email out of your total number of subscribers. Make sure you're sending out tons of emails! Over 20% open rates are the norm. If this doesn't sound possible for your business, review tips on how to increase email delivery rates with ease.</Policy.Text>
            <Policy.SubTitle as="h5">f) Reply rate</Policy.SubTitle>
            <Policy.Text>You should make sure to reply promptly and not leave prospects hanging in order for them not to be tempted to move on. Make your open rates higher than 4%, with one follow-up email after getting their initial response!</Policy.Text>
            <Policy.SubTitle as="h5">g) Open out rate</Policy.SubTitle>
            <Policy.Text>To maintain the quality of your email campaigns, it is important that you offer opt-out links in all outgoing emails. Your contacts should have a chance to unsubscribe from receiving future messages if they do not want this type or subject matter and can take advantage by clicking on one now before printing their copy for instance! Keep rates under 10%.</Policy.Text>
            <Policy.SubTitle as="h5">h) Can-Spam Act</Policy.SubTitle>
            <Policy.Text>The CAN-SPAM Act is a law that regulates commercial emails and gives recipients the right to have you stop sending them. It also sets harsh penalties for violations, so it's important not only to follow these rules but to keep yourself informed about any changes in regulations as well! The CAN-SPAM Act doesn't prohibit email advertising, but it prohibits certain fraudulent practices related to email advertising, such as using false or misleading identity information (“From,” “To,” and “Reply to”) or deceptive subject lines.</Policy.Text>
            <Policy.SubTitle as="h5">i) No generic emails</Policy.SubTitle>
            <Policy.Text>Please make sure that you are not using a generic email address. Changing your business email address to something more professional can help you be taken seriously and also keep customers engaged with the company.</Policy.Text>
            <Policy.SubTitle as="h5">j) Email attachments</Policy.SubTitle>
            <Policy.Text>Sending an email attachment is a great way to get your message across, but if you use too many of these in one go, it could put some people off. We recommend minimizing their usage and making sure each batch has only necessary files so they don't get filtered as spam!</Policy.Text>
          </Policy.ContentBox>
          <Policy.ContentBox>
            <Policy.TitleSmall as="h3">2. We-Connect’S COMMANDMENTS</Policy.TitleSmall>
            <Policy.SubTitle as="h5">a) Compliance with our sending metrics</Policy.SubTitle>
            <Policy.Text>We-Connect cares about the deliverability and safety of our system. We highly encourage our users to comply with high standards of sending. Furthermore, we highlight that there is a variety of applicable laws and regulations applying to outbound sending. Please familiarize yourself with applicable rules that may affect your sending process. What is more, regular maintenance of your prospects’ list will ensure that you are emailing an engaged audience with active email addresses.</Policy.Text>
            <Policy.Text>Maintaining a list of customers who regularly read your content and find value in your messaging will result in fewer bounced or blocked emails. While the process of removing inactive or incorrect email addresses may make your contact list look smaller, it will significantly improve your deliverability (and sender reputation) in the long run. Other things to keep in mind for good practices are: writing subject lines that are reflective of the content within the emails & avoiding promotional terms or common spam words, adding personalization.</Policy.Text>
            <Policy.SubTitle as="h5">b) Third-party mailing lists acquisition</Policy.SubTitle>
            <Policy.Text>Sending campaigns to contact lists that have been acquired (bought, exchanged or rented) from a third-party company where you are not explicitly named will negatively impact deliverability and may be deemed illegal but a plurality of jurisdictions. As a result, email addresses must always be collected in a way where the recipient has explicitly opted in to receive communication from you and/or that fully complies with national regulations that define consent collection, opt-in lists and other applicable requirements.</Policy.Text>
            <Policy.Text>Maintaining a list of customers who regularly read your content and find value in your messaging will result in fewer bounced or blocked emails. While the process of removing inactive or incorrect email addresses may make your contact list look smaller, it will significantly improve your deliverability (and sender reputation) in the long run. Other things to keep in mind for good practices are: writing subject lines that are reflective of the content within the emails & avoiding promotional terms or common spam words, adding personalization.</Policy.Text>
            <Policy.SubTitle as="h5">c) Consent to send messages</Policy.SubTitle>
            <Policy.Text>Some messages, except transactional emails, shall be sent only with prior consent or an equivalent legal ground to conduct such data processing. All your contacts should have granted clear, explicit and provable consent to receive communication. This consent should be granted through a confirmed simple opt-in or double opt-in system that clearly expresses the topic of the subscription on an online or offline form via a checkbox. This checkbox must not be checked by default.</Policy.Text>
            <Policy.SubTitle as="h5">d) Abuse complaints</Policy.SubTitle>
            <Policy.Text>We-Connect takes every abuse complaint, including complaints about lawful data processing very seriously. Please familiarize yourself with our Terms of Service, Privacy Policy and GDPR compliance before you start using our Service to send messages.</Policy.Text>
            <Policy.SubTitle as="h5">e) Acceptable use</Policy.SubTitle>
            <Policy.Text>Your use of our services must comply with all applicable laws and regulations in effect, including data protection and privacy laws, as well as our Terms of Service, Privacy Policy and GDPR compliance.</Policy.Text>
          </Policy.ContentBox>
            </Policy.Content>   
      </Col>
    </Row>
  </Container>
</Policy>

)
}